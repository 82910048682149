import { createSlice } from '@reduxjs/toolkit';

const SideBarToggleSlice = createSlice({
  name: 'toggle',
  initialState: false,
  reducers: {
    sideBartoggle: (state) => !state,
  },
});



export const { sideBartoggle } = SideBarToggleSlice.actions;
export {SideBarToggleSlice};



const FilterBoxToggleToggleSlice = createSlice({
  name: 'toggleFilterBoxToggle',
  initialState: false,
  reducers: {
    filterBoxToggleToggleFun: (state) => !state,
  },
});



export const { filterBoxToggleToggleFun } = FilterBoxToggleToggleSlice.actions;
export {FilterBoxToggleToggleSlice};

