import { createSlice } from '@reduxjs/toolkit';
import STATUSES from '../../Statuses';

const createApiSlice = (sliceName, extraReducersBuilder, useResetReducer = false, initialState = {}) => {
    return createSlice({
        name: sliceName,
        initialState: {
            data: {},
            status: STATUSES.IDEAL,
            error: null,
            ...initialState,
        },
        reducers: useResetReducer ? {
            resetState: (state) => {
                state.error = null;
                state.status = STATUSES.IDEAL;
                state.data = {};
            }
        } : {},
        extraReducers: (builder) => {
            builder
                .addCase(extraReducersBuilder.pending, (state) => {
                    state.status = STATUSES.LOADING
                })
                .addCase(extraReducersBuilder.fulfilled, (state, action) => {
                    state.data = action.payload
                    state.status = STATUSES.SUCCESS
                    state.error = null
                })
                .addCase(extraReducersBuilder.rejected, (state, action) => {
                    state.error = action.payload
                    state.status = STATUSES.ERROR
                });
        },
    });
};


export default createApiSlice;

// // Use the common slice creator
// const productGroupSlice = createApiSlice('ProductGroup', createProductAttributeGroup);

// // Export the reducer and actions
// export const { resetState: resetProductGroupState } = productGroupSlice.actions;
// export {productGroupSlice };