import axios from "axios";
import ApiEndPoint from "./apiUrls.json";

// Set base URL
const serviceURL = "https://api.onified.ai/";

// Create Axios instance with base URL
const axiosClient = axios.create({
  baseURL: serviceURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// Show/hide loading spinner function
let ongoingRequests = 0;
const toggleLoadingSpinner = (show) => {
  const loadingSpinner = document.getElementById("loading-spinner");
  if (loadingSpinner) {
    loadingSpinner.style.display = show ? "block" : "none";
  }
};

// Axios request interceptor
axiosClient.interceptors.request.use(
  (config) => {
    if (config?.loadingSpinner) {
      ongoingRequests++;
      toggleLoadingSpinner(true);
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Helper functions for different request types
const getRequest = async (URL, params = "", spinnerValue = true) => {
  try {
    const data = await axiosClient.get(`${URL}${params ? '/' + params : ''}`, {
      loadingSpinner: spinnerValue,
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

const postRequest = async (URL, payload, spinnerValue = true) => {
  try {
    const data = await axiosClient.post(URL, payload, { loadingSpinner: spinnerValue });
    return data;
  } catch (error) {
    return error.response;
  }
};

const postMultipartRequest = async (URL, payload, spinnerValue = true) => {
  try {
    const data = await axiosClient.post(URL, payload, {
      headers: { "Content-Type": "multipart/form-data" },
      loadingSpinner: spinnerValue,
    });
    return data;
  } catch (error) {
    return error.response;
  }
};

const patchRequest = async (URL, payload, spinnerValue = true) => {
  try {
    const data = await axiosClient.patch(URL, payload, { loadingSpinner: spinnerValue });
    return data;
  } catch (error) {
    return error.response;
  }
};

const putRequest = async (URL, payload, spinnerValue = true) => {
  try {
    const data = await axiosClient.put(URL, payload, { loadingSpinner: spinnerValue });
    return data;
  } catch (error) {
    return error.response;
  }
};

const deleteRequest = async (URL, spinnerValue = true) => {
  try {
    const data = await axiosClient.delete(URL, { loadingSpinner: spinnerValue });
    return data;
  } catch (error) {
    return error.response;
  }
};

// Export API functions
export {
  ApiEndPoint,
  getRequest,
  postRequest,
  patchRequest,
  putRequest,
  deleteRequest,
  postMultipartRequest,
};
