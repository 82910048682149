// // src/utils/Rehydrate.js
// import React, { useEffect } from 'react';
// import { useDispatch } from 'react-redux';
// import Cookies from 'js-cookie';
// import { rehydrateAuth } from '../../../store/Slices/userAuthSlice';

// const Rehydrate = ({ children }) => {
//   const dispatch = useDispatch();

//   useEffect(() => {
//     const token = Cookies.get('onifiedPKtoken');
//     const userDetails = JSON.parse(localStorage.getItem('onifiedPKData'));

//     if (token && userDetails) {
//       dispatch(rehydrateAuth({ token, userDetails }));
//     }
//   }, [dispatch]);

//   return children;
// };

// export default Rehydrate;


import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { rehydrateAuth } from '../../../store/Slices/userAuthSlice';
import Loader from '../../Common/Loader/Loader';

const Rehydrate = ({ children }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = Cookies.get('onifiedPKtoken');
    const userDetails = JSON.parse(localStorage.getItem('onifiedPKData'));

    if (token && userDetails) {
      dispatch(rehydrateAuth({ token, userDetails }));
    }

    setLoading(false);
  }, [dispatch]);

  if (loading) {
    return <Loader/>; 
  }

  return children;
};

export default Rehydrate;

