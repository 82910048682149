import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import axios from 'axios';
import STATUSES from '../Statuses';
import config from '../../config';

// const serviceURL0 = config.SERVICE_0_URL;
const serviceURL1 = config.SERVICE_1_URL;
const serviceURL2 = config.SERVICE_2_URL;
const serviceURL3 = config.SERVICE_3_URL;
const serviceURL4 = config.SERVICE_4_URL;




// All Delete delete API in state
const deleteDataInTableSlice = createSlice({
    name : "deleteDataInTable",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetDeleteDataInTableState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            isAnyOf(deleteProduct.pending, deleteRetailer.pending , deleteManufacturer.pending , deleteSupplier.pending),
            (state) => {
                state.status = STATUSES.LOADING;
            }
        );

        builder.addMatcher(
            isAnyOf(deleteProduct.fulfilled, deleteRetailer.fulfilled  , deleteManufacturer.fulfilled , deleteSupplier.fulfilled),
            (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.SUCCESS;
                state.error = null;
            }
        );

        builder.addMatcher(
            isAnyOf(deleteProduct.rejected, deleteRetailer.rejected  , deleteManufacturer.rejected , deleteSupplier.rejected),
            (state, action) => {
                state.error = action.payload;
                state.status = STATUSES.ERROR;
            }
        );
    },
});


export const deleteProduct = createAsyncThunk( "Product/delete", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};
        const responce = await axios.delete(`${serviceURL1}/product/deleteProduct/${data.className}/${data.productId}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// update Product ==  Product update API
const updateProductSlice = createSlice({
    name : "ProductUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdateProductState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateProduct.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updateProduct.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updateProduct.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 
export const updateProduct = createAsyncThunk( "Product/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const { className, productId, ...restData } = data; 
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL1}/product/updateProduct/${className}/${productId}`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})







// Delete Retailer == Retailer delete API
export const deleteRetailer = createAsyncThunk( "Retailer/delete", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};
        const responce = await axios.delete(`${serviceURL2}/retailers/deleteRetailer/${data.className}/${data.retailerId}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// update Retailer ==  Retailer update API
const updateRetailerSlice = createSlice({
    name : "RetailerUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdateRetailerState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateRetailer.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updateRetailer.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updateRetailer.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 
export const updateRetailer = createAsyncThunk( "Retailer/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const { className, retailerId, ...restData } = data; 
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL2}/retailers/updateRetailer/${className}/${retailerId}`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})







// Delete Manufacturer == Manufacturer delete API
export const deleteManufacturer = createAsyncThunk( "Manufacturer/delete", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};
        const responce = await axios.delete(`${serviceURL3}/manufacturers/deleteManufacturer/${data.className}/${data.manufacturerId}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// update Manufacturer ==  Manufacturer update API
const updateManufacturerSlice = createSlice({
    name : "ManufacturerUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdateManufacturerState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateManufacturer.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updateManufacturer.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updateManufacturer.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 
export const updateManufacturer = createAsyncThunk( "Manufacturer/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const { className, manufacturerId, ...restData } = data; 
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL3}/manufacturers/updateManufacturer/${className}/${manufacturerId}`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})







// Delete Supplier == Supplier delete API
export const deleteSupplier = createAsyncThunk( "Supplier/delete", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};
        const responce = await axios.delete(`${serviceURL4}/suppliers/deleteSupplier/${data.className}/${data.supplierId}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// update Supplier ==  Supplier update API
const updateSupplierSlice = createSlice({
    name : "SupplierUpdate",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetUpdateSupplierState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(updateSupplier.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })
        
        .addCase(updateSupplier.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })
        
        .addCase(updateSupplier.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
}); 
export const updateSupplier = createAsyncThunk( "Supplier/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const { className, supplierId, ...restData } = data; 
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL4}/suppliers/updateSupplier/${className}/${supplierId}`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})







export {
    deleteDataInTableSlice,
    updateProductSlice,
    updateRetailerSlice,   
    updateManufacturerSlice,
    updateSupplierSlice,
   
  
}



export const {resetUpdateProductState} = updateProductSlice.actions;
export const {resetUpdateRetailerState} = updateRetailerSlice.actions;
export const {resetUpdateManufacturerState} = updateManufacturerSlice.actions;
export const {resetUpdateSupplierState} = updateSupplierSlice.actions;



export const {resetDeleteDataInTableState} = deleteDataInTableSlice.actions;
