// In your liveAPI.js slice file
import { createSlice } from '@reduxjs/toolkit';

const liveAPISlice = createSlice({
  name: 'liveAPI',
  initialState :  {
    selectedHUNumber: "",   
    // selectedBatchName: "XBJ30B019A",   
    selectedBatchName: "",   
  },
  reducers: {
    // other reducers

    // Add this reducer to update the selected HU number
    setSelectedHUNumber(state, action) {
      // If a batch name is present, clear it
      if (state.selectedBatchName) {
        state.selectedBatchName = "";
      }
      state.selectedHUNumber = action.payload;
    },

    setSelectedBatchName(state, action) {
      // If an HU number is present, clear it
      if (state.selectedHUNumber) {
        state.selectedHUNumber = "";
      }
      state.selectedBatchName = action.payload;
    },
  },
});

// Export the action
export const { setSelectedHUNumber , setSelectedBatchName } = liveAPISlice.actions;

export {liveAPISlice};
