import { createSlice } from '@reduxjs/toolkit';



const selectedAttributesSlice = createSlice({
  name: 'selectedAttributes',
  initialState: [],
  reducers: {
    setSelectedAttributesHeading: (state, action) => {
      return action.payload
    },
  },
});

export const { setSelectedAttributesHeading} = selectedAttributesSlice.actions;


const selectedQuerysSlice = createSlice({
  name: 'selectedQuerys',
  initialState: {},
  reducers: {
    addSelectedQuery: (state, action) => {
      const { key, nametoDisplay, value } = action.payload;
      if (value.length > 0) {
        state[key] = { nametoDisplay, value };
      } else {
        delete state[key];
      }
    },
    removeSelectedQuery: (state, action) => {
      const { key } = action.payload;
      delete state[key];
    },
    clearAllSelectedQuerys: () => {
      return {};
    },
  },
});
export const { addSelectedQuery, removeSelectedQuery , clearAllSelectedQuerys} = selectedQuerysSlice.actions;


// table filter query and headings 
const selectedFilterTableAttributesSlice = createSlice({
  name: 'selectedFilterTableAttributes',
  initialState: [],
  reducers: {
    setSelectedFilterTableAttributesHeading: (state, action) => {
      return action.payload
    },
  },
});

export const { setSelectedFilterTableAttributesHeading} = selectedFilterTableAttributesSlice.actions;


const selectedFilterTableQuerysSlice = createSlice({
  name: 'selectedFilterTableQuerys',
  initialState: {},
  reducers: {
    addSelectedFilterTableQuery: (state, action) => {
      const { key, nametoDisplay, value } = action.payload;
      if (value.length > 0) {
        state[key] = { nametoDisplay, value };
      } else {
        delete state[key];
      }
    },
    removeSelectedFilterTableQuery: (state, action) => {
      const { key } = action.payload;
      delete state[key];
    },
    clearAllSelectedFilterTableQuerys: () => {
      return {};
    },
  },
});
export const { addSelectedFilterTableQuery, removeSelectedFilterTableQuery , clearAllSelectedFilterTableQuerys} = selectedFilterTableQuerysSlice.actions;


const DataSelectionNamesSlice = createSlice({
  name: 'stringState',
  initialState :  {
    tableName: '',  
    tableIndex: '',  
    groupName: '',  
    productGroupNameForProgram: '',  
  },
  reducers: {
    setToGraphPageTableName: (state, action) => {
      state.tableName = action.payload;
    },
    setToGraphPageTableIndex: (state, action) => {
      state.tableIndex = action.payload;
    },
    setToGraphPageGroupName: (state, action) => {
      state.groupName = action.payload;
    },
    setToGraphPageProductGroupNameForProgram: (state, action) => {
      state.productGroupNameForProgram = action.payload;
    },
    // Optional: add a reducer to set all three at once
    // setAllVars: (state, action) => {
    //   const { varName1, varName2, varName3 } = action.payload;
    //   state.varName1 = varName1;
    //   state.varName2 = varName2;
    //   state.varName3 = varName3;
    // }
  },
});

export const { setToGraphPageTableName, setToGraphPageTableIndex , setToGraphPageGroupName, setToGraphPageProductGroupNameForProgram } = DataSelectionNamesSlice.actions;




export {selectedQuerysSlice ,selectedAttributesSlice ,DataSelectionNamesSlice ,selectedFilterTableAttributesSlice ,selectedFilterTableQuerysSlice};