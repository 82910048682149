import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import STATUSES from '../Statuses';
import config from '../../config';
import createApiSlice from "./Common/CommoCreateSlice";

const serviceURL0 = config.SERVICE_0_URL;
const serviceURL1 = config.SERVICE_1_URL;
const serviceURL2 = config.SERVICE_2_URL;
const serviceURL3 = config.SERVICE_3_URL;
const serviceURL4 = config.SERVICE_4_URL;



// ======================================= Product ========================================

 
// const uploadProductImgSlice = createSlice({
//     name : "uploadProductImg",
//     initialState : {
//         data :{},
//         status : STATUSES.IDEAL,
//         error : null
//     },
//     extraReducers : (builder)=>{
//         builder
//         .addCase(uploadProductImg.pending, (state , action)=>{
//             state.status = STATUSES.LOADING
//         })

//         .addCase(uploadProductImg.fulfilled, (state , action)=>{
//             state.data = action.payload
//             state.status = STATUSES.SUCCESS
//             state.error = null
//         })

//         .addCase(uploadProductImg.rejected, (state , action)=>{
//             state.error = action.payload
//             state.status = STATUSES.ERROR
//         })
//     }
// });

// upload Product Img == post API
export const uploadProductImg = createAsyncThunk( "Product/UploadImg", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", },
            params: {productId :data.productId  ,imageName :data.imageName , productClassName : data.className}
        };

        const responce = await axios.post(`${serviceURL1}/product/uploadOrUpdateImagesForProduct`,data.formData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

const uploadProductImgSlice = createApiSlice('uploadProductImg', uploadProductImg , true );






// ======================================= Retailer ========================================

// const uploadRetailerImgSlice = createSlice({
//     name : "uploadRetailerImg",
//     initialState : {
//         data :{},
//         status : STATUSES.IDEAL,
//         error : null
//     },
//     extraReducers : (builder)=>{
//         builder
//         .addCase(uploadRetailerImg.pending, (state , action)=>{
//             state.status = STATUSES.LOADING
//         })

//         .addCase(uploadRetailerImg.fulfilled, (state , action)=>{
//             state.data = action.payload
//             state.status = STATUSES.SUCCESS
//             state.error = null
//         })

//         .addCase(uploadRetailerImg.rejected, (state , action)=>{
//             state.error = action.payload
//             state.status = STATUSES.ERROR
//         })
//     }
// });

// upload Retailer Img == post API
export const uploadRetailerImg = createAsyncThunk( "Retailer/UploadImg", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", },
            params: {retailerId :data.retailerId  ,imageName :data.imageName , retailerClassName :data.className }
        };

        const responce = await axios.post(`${serviceURL2}/retailers/uploadOrUpdateImagesForRetailer`,data.formData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

const uploadRetailerImgSlice = createApiSlice('uploadRetailerImg', uploadRetailerImg , true );






// ======================================= Manufacturer ========================================

// const uploadManufacturerImgSlice = createSlice({
//     name : "uploadManufacturerImg",
//     initialState : {
//         data :{},
//         status : STATUSES.IDEAL,
//         error : null
//     },
//     extraReducers : (builder)=>{
//         builder
//         .addCase(uploadManufacturerImg.pending, (state , action)=>{
//             state.status = STATUSES.LOADING
//         })

//         .addCase(uploadManufacturerImg.fulfilled, (state , action)=>{
//             state.data = action.payload
//             state.status = STATUSES.SUCCESS
//             state.error = null
//         })

//         .addCase(uploadManufacturerImg.rejected, (state , action)=>{
//             state.error = action.payload
//             state.status = STATUSES.ERROR
//         })
//     }
// });

// upload Manufacturer Img == post API
export const uploadManufacturerImg = createAsyncThunk( "Manufacturer/UploadImg", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", },
            params: {manufacturerId :data.manufacturerId  ,imageName :data.imageName , manufacturerClassName  : data.className}
            };

        const responce = await axios.post(`${serviceURL3}/manufacturers/uploadOrUpdateImagesForManufacturer`,data.formData,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

const uploadManufacturerImgSlice = createApiSlice('uploadManufacturerImg', uploadManufacturerImg , true );







// ======================================= Supplier ========================================

// const uploadSupplierImgSlice = createSlice({
//     name : "uploadSupplierImg",
//     initialState : {
//         data :{},
//         status : STATUSES.IDEAL,
//         error : null
//     },
//     extraReducers : (builder)=>{
//         builder
//         .addCase(uploadSupplierImg.pending, (state , action)=>{
//             state.status = STATUSES.LOADING
//         })

//         .addCase(uploadSupplierImg.fulfilled, (state , action)=>{
//             state.data = action.payload
//             state.status = STATUSES.SUCCESS
//             state.error = null
//         })

//         .addCase(uploadSupplierImg.rejected, (state , action)=>{
//             state.error = action.payload
//             state.status = STATUSES.ERROR
//         })
//     }
// });

// upload Supplier Img == post API
export const uploadSupplierImg = createAsyncThunk( "Supplier/UploadImg", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", },
            params: {supplierId :data.supplierId  ,imageName :data.imageName , supplierClassName  : data.className}
        };

        const responce = await axios.post(`${serviceURL4}/suppliers/uploadOrUpdateImagesForSupplier`,data.formData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

const uploadSupplierImgSlice = createApiSlice('uploadSupplierImg', uploadSupplierImg , true );










// ======================================= Program ========================================


 
// const uploadProgramImgSlice = createSlice({
//     name : "uploadProgramImg",
//     initialState : {
//         data :{},
//         status : STATUSES.IDEAL,
//         error : null
//     },
//     extraReducers : (builder)=>{
//         builder
//         .addCase(uploadProgramImg.pending, (state , action)=>{
//             state.status = STATUSES.LOADING
//         })

//         .addCase(uploadProgramImg.fulfilled, (state , action)=>{
//             state.data = action.payload
//             state.status = STATUSES.SUCCESS
//             state.error = null
//         })

//         .addCase(uploadProgramImg.rejected, (state , action)=>{
//             state.error = action.payload
//             state.status = STATUSES.ERROR
//         })
//     }
// });



export const uploadProgramImg = createAsyncThunk("Program/UploadImg", async (data, { rejectWithValue, getState }) => {
      try {
        const state = getState();
        const token = state.auth.token;
        const config = {
          headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", },
          params: { programId: data.programId, imageName: data.imageName , programClassName  : data.className},
        };
  
        const response = await axios.post(`${serviceURL0}/programs/uploadOrUpdateImagesForProgram`, data.formData, config);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
      }
    }
);


const uploadProgramImgSlice = createApiSlice('uploadProgramImg', uploadProgramImg , true );



const deleteProgramImgSlice = createSlice({
    name: 'deletePprogramImage',
    initialState: {
        data: {},
        status: STATUSES.IDLE,
        error: null
    },
    reducers : {
        resetdeleteProgramImgState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers : (builder)=>{
        builder
        .addCase(deleteProgramImg.pending, (state , action)=>{
            state.status = STATUSES.LOADING
        })

        .addCase(deleteProgramImg.fulfilled, (state , action)=>{
            state.data = action.payload
            state.status = STATUSES.SUCCESS
            state.error = null
        })

        .addCase(deleteProgramImg.rejected, (state , action)=>{
            state.error = action.payload
            state.status = STATUSES.ERROR
        })
    }
});

export const deleteProgramImg = createAsyncThunk("program/deleteImg",async (data, { rejectWithValue ,getState }) => {
    try {
            
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: { programId: data.programId, imageName: data.imageName , programClassName  : data.className}
        };

        const responce = await axios.delete(`${serviceURL0}/programs/deleteImagesForProgram`, config);
        return responce.data

        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);







export {
    uploadProductImgSlice,

    uploadRetailerImgSlice,

    uploadManufacturerImgSlice,

    uploadSupplierImgSlice,


    uploadProgramImgSlice,
    deleteProgramImgSlice
}

export const {resetdeleteProgramImgState} = deleteProgramImgSlice.actions;

