import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import axios from 'axios';
import STATUSES from '../Statuses';
import createApiSlice from "../Slices/Common/CommoCreateSlice";
import config from '../../config';

const serviceURL = config.SERVICE_TRACEABILITY_URL;

 

// =============================================== StageSetup APIS ============================================
const StageSetupSlice = createSlice({
    name : "StageSetup",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetStageSetupState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            isAnyOf(createStageSetup.pending, updateStageSetup.pending, deleteStageSetup.pending , createOperationSetup.pending , updateOperationSetup.pending , deleteOperationSetup.pending , createAPIFieldsName.pending , updateAPIFieldsName.pending , deleteAPIFieldsName.pending),
            (state) => {
                state.status = STATUSES.LOADING;
            }
        );

        builder.addMatcher(
            isAnyOf(createStageSetup.fulfilled, updateStageSetup.fulfilled, deleteStageSetup.fulfilled , createOperationSetup.fulfilled , updateOperationSetup.fulfilled , deleteOperationSetup.fulfilled , createAPIFieldsName.fulfilled , updateAPIFieldsName.fulfilled , deleteAPIFieldsName.fulfilled),
            (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.SUCCESS;
                state.error = null;
            }
        );

        builder.addMatcher(
            isAnyOf(createStageSetup.rejected, updateStageSetup.rejected, deleteStageSetup.rejected , createOperationSetup.rejected , updateOperationSetup.rejected , deleteOperationSetup.rejected ,  createAPIFieldsName.rejected , updateAPIFieldsName.rejected , deleteAPIFieldsName.rejected),
            (state, action) => {
                state.error = action.payload;
                state.status = STATUSES.ERROR;
            }
        );
    },
}); 



// Create Satge Setup
export const createStageSetup = createAsyncThunk( "StageSetup/create", async ( data, { rejectWithValue ,getState  })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.post(`${serviceURL}/stage/createStage-setup`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

  
// update StageSetup 
export const updateStageSetup = createAsyncThunk( "StageSetup/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL}/stage/updateStageById/${data.id}`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// delete StageSetup 
export const deleteStageSetup = createAsyncThunk( "StageSetup/delete", async ( data, { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};        
        
        const responce = await axios.delete(`${serviceURL}/stage/deleteStageById/${data.id}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


 
// Get all getAllStageSetup
export const getAllStageSetup = createAsyncThunk( "StageSetup/getAll", async ( {id=null} ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL}/stage/getAllStages`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const getAllStageSetupSlice = createApiSlice('getAllStageSetup', getAllStageSetup , true);



  
// ========================================== Operation ================================================ 



// Create Operation Setup
export const createOperationSetup = createAsyncThunk( "OperationSetup/create", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { stageId, ...restData } = data;

        const state = getState();
        const token = state.auth.token; 
        const config = {
             headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }, 
             params: { stageId}
        };

        const responce = await axios.post(`${serviceURL}/operation/createOperation`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})
  
// update OperationSetup 
export const updateOperationSetup = createAsyncThunk( "OperationSetup/update", async (  data , { rejectWithValue ,getState })=>{
    try {
        const { operationId, ...restData } = data;

        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.put(`${serviceURL}/operation/updateOperationById/${operationId}`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// delete OperationSetup 
export const deleteOperationSetup = createAsyncThunk( "OperationSetup/delete", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};
                
        const responce = await axios.delete(`${serviceURL}/operation/deleteOperationById/${data.id}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

 
// Get all getAllOperationSetup
export const getAllOperationSetup = createAsyncThunk( "OperationSetup/getAll", async ( {id=null} ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};
        
        const responce = await axios.get(`${serviceURL}/operation/getAllOperations`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});


const getAllOperationSetupSlice = createApiSlice('getAllOperationSetup', getAllOperationSetup , true);



const OperationImgSlice = createSlice({
    name : "StageSetup",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetOperationImgState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            isAnyOf(uploadOperationImg.pending, deleteOperationImg.pending),
            (state) => {
                state.status = STATUSES.LOADING;
            }
        );

        builder.addMatcher(
            isAnyOf(uploadOperationImg.fulfilled, deleteOperationImg.fulfilled),
            (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.SUCCESS;
                state.error = null;
            }
        );

        builder.addMatcher(
            isAnyOf(uploadOperationImg.rejected, deleteOperationImg.rejected),
            (state, action) => {
                state.error = action.payload;
                state.status = STATUSES.ERROR;
            }
        );
    },
}); 


export const uploadOperationImg = createAsyncThunk( "OperationSetup/UploadImg", async ( data, { rejectWithValue ,getState })=>{
    try {
        
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data", },
            params: {operationId :data.operationId  ,imageName :data.imageName }
        };

        const responce = await axios.post(`${serviceURL}/operation/uploadOrUpdateImagesForOperation`,data.formData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

export const deleteOperationImg = createAsyncThunk("OperationSetup/deleteImg",async (data, { rejectWithValue ,getState }) => {
    try {
            
        const state = getState();
        const token = state.auth.token; 
        const config = { 
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params: {operationId :data.operationId  ,imageName :data.imageName }
        };

        const responce = await axios.delete(`${serviceURL}/operation/deleteImagesForOperation`, config);
        return responce.data

        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);





// ========================================== API Fields Name ================================================ 

// Create API Fields Name
export const createAPIFieldsName = createAsyncThunk( "APIFieldsName/create", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.post(`${serviceURL}/productFields/createProductFields`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

  
// update API Fields Name 
export const updateAPIFieldsName = createAsyncThunk( "APIFieldsName/update", async (  data , { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};
        
        const responce = await axios.put(`${serviceURL}/productFields/updateProductById/${data.id}`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// delete API Fields Name 
export const deleteAPIFieldsName = createAsyncThunk( "APIFieldsName/delete", async ( data, { rejectWithValue ,getState })=>{
    try {
                
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.delete(`${serviceURL}/productFields/deleteProductById/${data.id}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

 
// Get all API Fields Name
export const getAllAPIFieldsName = createAsyncThunk( "APIFieldsName/getAll", async ( {id=null} ,{ rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" }};

        const responce = await axios.get(`${serviceURL}/productFields/getAllProductFields`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const getAllAPIFieldsNameSlice = createApiSlice('getAllAPIFieldsName', getAllAPIFieldsName , true);






  




 

export {
    StageSetupSlice,
    getAllStageSetupSlice,
    getAllOperationSetupSlice,
    getAllAPIFieldsNameSlice,
    OperationImgSlice
}

export const {resetStageSetupState} = StageSetupSlice.actions;
export const {resetState : resetGetAllStageSetup} = getAllStageSetupSlice.actions;
export const {resetState : resetGetAllOperationSetup} = getAllOperationSetupSlice.actions;
export const {resetState : resetGetAllAPIFieldsName} = getAllAPIFieldsNameSlice.actions;
export const {resetOperationImgState} = OperationImgSlice.actions;



