import React, { useEffect, useRef, useState } from 'react';
import { DropdownBox, InputBox } from '../../Components/Common/commonFun';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { createAPIFieldsName, deleteAPIFieldsName, getAllAPIFieldsName, resetStageSetupState, updateAPIFieldsName } from '../../../../store/TraceabilitySlice/stageSetup';
import { useSelector } from 'react-redux';
import STATUSES from '../../../../store/Statuses';
import Loader, { LoaderCircle } from '../../../Common/Loader/Loader';
import ErrorBox from '../../../Common/ErrorBox/ErrorBox';
import RenderWarning from '../../../MarketMaps/MarketMapsComponents/RenderWarning/RenderWarning';
import { toast } from 'react-toastify';


const ApiFields = () => {

    const dispatch = useDispatch();

    const [showFieldsBox, setShowFieldsBox] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [fieldToUpdate, setFieldToUpdate] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [isDeleteAction, setIsDeleteAction] = useState(false);
    const tableRef = useRef(null);
    
    const handleDeleteField = (id) => {
      if (window.confirm("Are you sure you want to delete this? It will be permanently removed.")) {
        setIsDeleteAction(true);
        setShowFieldsBox(false);
        dispatch(deleteAPIFieldsName({id}));
      }
    };

    const { data: deleteData , status : deleteStatus,  error :deleteError } = useSelector((state) => state.StageSetup);

    useEffect(() => {
      if (deleteStatus === STATUSES.SUCCESS && isDeleteAction) {
        toast.success(deleteData?.message);
        dispatch(resetStageSetupState({}));
        dispatch(getAllAPIFieldsName({}));
        setIsDeleteAction(false);
      } else if (deleteStatus === STATUSES.ERROR && isDeleteAction) {
        toast.error(deleteError?.message || "Something Went Wrong");
        dispatch(resetStageSetupState({}));
        setIsDeleteAction(false);
      }
    }, [dispatch , deleteStatus, deleteError, deleteData , isDeleteAction]);
  
    // Function to edit a field
    const editField = (field) => {
      setFieldToUpdate(field);
      setShowFieldsBox(true);
      setIsUpdating(true);
    };
  
    // Toggle showing operations column
    const toggleOperations = () => {
      setEditMode(!editMode);
      setShowFieldsBox(false);
       // Scroll to the operations column after it becomes visible
       if (!editMode && tableRef.current) {
        setTimeout(() => {
          const operationsColumn = tableRef.current.querySelector('.operations');
          if (operationsColumn) {
            operationsColumn.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'end' });
          }
        }, 100);
      }
    };

    useEffect(() => {
      dispatch(getAllAPIFieldsName({}));
    }, [dispatch]);

    const { data , status , error} = useSelector((state) => state.AllAPIFieldsName);

  
    return (
      <div className="common_setting_page">
        <header>
          <p>Data Fields Settings</p>
          <div>
            <button className="common_btn" onClick={() => {setShowFieldsBox(true); setEditMode(false); setFieldToUpdate(null);} }>
             Add Data
            </button>
  
            {data?.length > 0 && (
              <button className="common_btn" onClick={toggleOperations}>
                {editMode ? "Hide Update" : "Update"}
              </button>
            )}
          </div>
        </header>
  
        <div className="common_setting_container">
               
            {
              status === STATUSES.LOADING ? ( <Loader/>) :
              status === STATUSES.ERROR ? ( <ErrorBox message={error?.message}/>) :
              status === STATUSES.SUCCESS ? (
                typeof data === "string" ? <RenderWarning message={`No data found, please add data`} /> :
                  <table className="common_setting_table" ref={tableRef}>   
                    <tbody>
                    <tr>
                      <td>Sl No.</td>
                      <td>Product Type</td>
                      <td>API Name</td>
                      <td>API Field Name</td>
                      <td>Sample value</td>
                      <td>Onified Field Name</td>
                      <td>Display Name</td>
                      <td>Description</td>
                      {editMode && <td>Operations</td>}
                    </tr>
                    {
                      data.map((field, index)=> (
                    
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{field.productType}</td>
                          <td>{field.apiNames}</td>
                          <td>{field.apiFieldName}</td>
                          <td>{field.sampleValue}</td>
                          <td>{field.fieldName}</td>
                          <td>{field.fieldDisplayName}</td>
                          <td>{field.description}</td>
                          {editMode && (
                            <td className='operations'>
                              <i onClick={() => editField(field)}><FontAwesomeIcon icon={faPen}/></i>
                              <i onClick={() => handleDeleteField(field.id)}><FontAwesomeIcon icon={faTrash}/></i>
                            </td>
                          )}
                        </tr>
                    ))
                    }
                  </tbody>
                  </table>
              ) : null
            }
        </div>
  
        {showFieldsBox && (
          <CreateFieldsName
            closeFun={() => {
              setShowFieldsBox(!showFieldsBox);
              setIsUpdating(false);
              setFieldToUpdate(null);
            }}
            fieldToUpdate={isUpdating ? fieldToUpdate : null}
          />
        )}
      </div>
    );
  };
  
export default ApiFields;



const CreateFieldsName = ({ closeFun, fieldToUpdate }) => {
  const dispatch = useDispatch();
  const isEdit = !!fieldToUpdate;
  const [isSaveOrUpdateAction, setIsSaveOrUpdateAction] = useState(false);

  const [fieldDetails, setFieldDetails] = useState({
    fieldName: fieldToUpdate?.fieldName || '',
    productType: fieldToUpdate?.productType || '',
    fieldDisplayName : fieldToUpdate?.fieldDisplayName || '',
    sampleValue : fieldToUpdate?.sampleValue || '',
    apiFieldName: fieldToUpdate?.apiFieldName || '',
    description: fieldToUpdate?.description || '',
    apiNames: fieldToUpdate?.apiNames || '',
  });

  useEffect(()=>{
    if(!isEdit){
      setFieldDetails({ fieldName: '', productType: '', fieldDisplayName : '', sampleValue : '', apiFieldName: '', description: '' , apiNames : ''});
    }
  },[isEdit])

  const [errors, setErrors] = useState({
    fieldName: '',
    productType: '',
    fieldDisplayName : '',
    sampleValue : '',
    apiFieldName: '',
    description: '',
    apiNames: '',
  });


  const validateFields = () => {
    const newErrors = {
      fieldName: !fieldDetails.fieldName.trim() ? 'Onified Field Name is required' : '',
      productType: !fieldDetails.productType.trim() ? 'Product type is required' : '',
      fieldDisplayName: !fieldDetails.fieldDisplayName.trim() ? 'Field Display Name is required' : '',
      sampleValue: !fieldDetails.sampleValue.trim() ? 'Sample value is required' : '',
      apiFieldName: !fieldDetails.apiFieldName.trim() ? 'API Field Name is required' : '',
      description: !fieldDetails.description.trim() ? 'Description is required' : '',
      apiNames: !fieldDetails.apiNames.trim() ? 'API name is required' : '',
    };
    setErrors(newErrors);
    return !newErrors.fieldName  &&  !newErrors.productType  && !newErrors.fieldDisplayName  && !newErrors.sampleValue && !newErrors.apiFieldName && !newErrors.description && !newErrors.apiNames;
  };

  const handleDataChange = (e) => {
    setFieldDetails({ ...fieldDetails, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const handleSave = () => {
    if (validateFields()) {
      console.log("stageData" , fieldDetails)
      setIsSaveOrUpdateAction(true);
      if (isEdit) {
        dispatch(updateAPIFieldsName({ ...fieldDetails, id: fieldToUpdate.id }));
      } else {
        dispatch(createAPIFieldsName(fieldDetails));
      }
    }
  };

  const { data, status, error } = useSelector((state) => state.StageSetup);

  useEffect(() => {
    if (status === STATUSES.SUCCESS && isSaveOrUpdateAction) {
      toast.success(data?.message);
      dispatch(resetStageSetupState({}));
      setIsSaveOrUpdateAction(false);
      closeFun();
      dispatch(getAllAPIFieldsName({}));
    } else if (status === STATUSES.ERROR && isSaveOrUpdateAction) {
      toast.error(error?.message || "Something Went Wrong");
      dispatch(resetStageSetupState({}));
      setIsSaveOrUpdateAction(false);
    }
  }, [status, error, dispatch, closeFun , data , isSaveOrUpdateAction]);


  const boxRef = useRef(null);

  useEffect(() => {
    if (boxRef.current) {
      boxRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div className="tt_settings_popup" ref={boxRef}>
      <h6>{fieldToUpdate ? "Update Field" : "Add Field"}</h6>
      <div className="tt_settings_popup_box">

        <DropdownBox
          labelName="Product type"
          options={[ "Sheeting" , "Towel" ,  "Sheeting and Towel"]}
          selectedValue={fieldDetails.productType}
          handleSelect={handleDataChange}
          name="productType"
          error={errors.productType}
        />

        <InputBox
          labelName="API Name"
          DataType="text"
          labelValue="apiNames"
          value={fieldDetails.apiNames}
          handleFun={handleDataChange}
          error={errors.apiNames}
        />
        
        <InputBox
          labelName="API Field Name"
          DataType="text"
          labelValue="apiFieldName"
          value={fieldDetails.apiFieldName}
          handleFun={handleDataChange}
          error={errors.apiFieldName}
        />
        
        <InputBox
          labelName="Sample value"
          DataType="text"
          labelValue="sampleValue"
          value={fieldDetails.sampleValue}
          handleFun={handleDataChange}
          error={errors.sampleValue}
        />
        <InputBox
          labelName="Onified Field Name"
          DataType="text"
          labelValue="fieldName"
          value={fieldDetails.fieldName}
          handleFun={handleDataChange}
          error={errors.fieldName}
        />


        <InputBox
          labelName="Field Display Name"
          DataType="text"
          labelValue="fieldDisplayName"
          value={fieldDetails.fieldDisplayName}
          handleFun={handleDataChange}
          error={errors.fieldDisplayName}
        />




        <InputBox
          labelName="Description"
          DataType="text"
          labelValue="description"
          value={fieldDetails.description}
          handleFun={handleDataChange}
          error={errors.description}
        />


      </div>

      <section>
        <button onClick={handleSave} className={status === STATUSES.LOADING ? 'common_btn disabled' : 'common_btn'} disabled={status === STATUSES.LOADING}>
          {status === STATUSES.LOADING ? <LoaderCircle /> : (isEdit ? "Update Field" : "Save Field")}
        </button>
        <button className="common_btn" onClick={closeFun}> Cancel </button>
      </section>
    </div>
  );
};
